<template>
  <div>
    <h2>กรุณารอสักครู่กำลังเข้าสู่เกมส์.....</h2>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return { message: 'Hello Vue.js!' }
  },
  methods: {
    async getLoginUrl() {
      // const game = this.$route.params.casino
      const providor = this.$route.query.p
      const gameId = this.$route.query.g
      const url = await this.$http.post(`member/gameplay/login`, {
        provider: providor,
        gameId,
        tab: 'Slot'
      })

      console.log(url.data)
      if (url.data.result.code === 0) {
        console.log(url.data.result.data.uri)
        // window.open(url.data.url.url, '_blank')
        setTimeout(() => {
          window.location.assign(url.data.result.data.uri)
        }, 100)
      } else {
        alert('ไม่สามารถเข้าสู่หน้าเล่นได้ชั่วคราว')
        window.history.back()
      }
    }
  },
  async beforeMount() {
    await this.getLoginUrl()
  }
}
</script>
